canvas {
  border: 1px solid;
  margin: auto;
}

:focus {
  outline: none;
}

/*# sourceMappingURL=index.cea51033.css.map */
